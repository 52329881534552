import * as THREE from 'three';
import Application from './Application.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Vector3 } from 'three';
import Accelerometer from './Utils/Accelerometer.js';
import Debug from './Utils/Debug.js'
import { gsap } from "gsap";

export default class Camera
{
    constructor(_app)
    {
        // creates a new istance, but it's a singleton, so the already existing istance is provided
        this.app = _app ?? new Application()
        this.sizes = this.app.sizes
        this.pointer = this.app.pointer
        this.scene = this.app.scene
        this.canvas = this.app.canvas;
        this.params = this.app.configuration.parameters;
        this.debug = new Debug()
        this.accelerometer = new Accelerometer();
        this.isAnimating = false;
        
        this.target = new Vector3(0,0,0);

        this.debugString = "";

        if (this.pointer)
            this.pointer.on('move', () => {this.userMoves()})
        if (this.accelerometer.active)
            this.accelerometer.on('motion', () => {this.userMoves()})

        this.setInstance()
        this.setControls()

    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(
            this.params.camera.FOV, 
            this.sizes.width / this.sizes.height, 
            this.params.camera.near,
            this.params.camera.far)
        this.instance.position.set(
            this.params.camera.position.x,
            this.params.camera.position.y,
            this.params.camera.position.z);

        if (this.app.debug.active) {
            this.debug.addFolder("camera");
            this.debug.folders["camera"].add(this,'debugString').listen();
            this.debug.folders["camera"].add(this.instance.position,'x').listen();
            this.debug.folders["camera"].add(this.instance.position,'y').listen();
            this.debug.folders["camera"].add(this.instance.position,'z').listen();
        }

        this.instance.lookAt(this.target);
        this.scene.add(this.instance)
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.dampingFactor = this.params.camera.orbitSmooth;
        this.controls.maxPolarAngle = Math.PI/2+this.params.camera.maxPolarAngle/2;
        this.controls.minPolarAngle = Math.PI/2-this.params.camera.maxPolarAngle/2;
        this.controls.enablePan = false;
        this.controls.maxDistance = this.params.camera.position.z+this.params.camera.maxDistance;
        this.controls.minDistance = this.params.camera.position.z-this.params.camera.maxDistance;
        this.controls.maxAzimuthAngle = this.params.camera.maxAzimuthAngle;
        this.controls.minAzimuthAngle = -this.params.camera.maxAzimuthAngle;
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        if (this.isAnimating) {
            // movement controlled by animation          
            this.instance.updateMatrix();
        }
        else {
            // movement controlled by controls or mouse/device movement
            this.controls.update()
            this.accelerometer.update();    
        }
    }

    /**
     * Handles mouse and device movement
     */
    userMoves() {
        if (this.isAnimating)
            return; // animation in progress

        var distanceX = this.pointer.mouseSpeed.x / this.params.camera.mouseAnimationAmplitude;
        var distanceY = this.pointer.mouseSpeed.y / this.params.camera.mouseAnimationAmplitude;
        if (this.accelerometer.rotationRate && this.accelerometer.rotationRate.beta) {
            // we have acceleration data
            distanceX = this.accelerometer.rotationRate.beta / this.params.camera.deviceAnimationAmplitude;
            distanceY = -this.accelerometer.rotationRate.alpha / this.params.camera.deviceAnimationAmplitude;
        }
        this.instance.position.x = THREE.MathUtils.lerp(this.instance.position.x, this.instance.position.x+distanceX, this.params.camera.animationSmooth);
        this.instance.position.y = THREE.MathUtils.lerp(this.instance.position.y, this.instance.position.y+distanceY, this.params.camera.animationSmooth);
        this.instance.updateMatrix()
    }

    /**
     * move the camera in the inside position (if argument true) or back (if argument false)
     */
    moveAnimation(inside) {
        this.isAnimating = true;
        var destinationX=this.params.camera.positionInside.x;
        var destinationY=this.params.camera.positionInside.y;
        var destinationZ=this.params.camera.positionInside.z;
        if (!inside) {
            destinationX=this.params.camera.position.x;
            destinationY=this.params.camera.position.y;
            destinationZ=this.params.camera.position.z;
        }
        gsap.to(this.instance.position, {
            duration: this.params.camera.movingDuration,
            ease: this.params.camera.movingEase,
            x: destinationX,
            y: destinationY,
            z: destinationZ,
            onComplete: () => {
                this.isAnimating = inside; // restore camera controls only when going outside
                }
            });
    }
}