import SplitText from "../Lib/SplitText.min";             //https://greensock.com/docs/v3/Plugins/SplitText

export default {
    debug:false,                                     // set to false in production

    imageQuery:"https://marongiu.nofuffa.com/wp-json/marongiu/v1/images/",  // base JSON address
    imageQueryCategories: {                                                 // appended to JSON adress to select galleries
        home: "home",
        gallery: "campagne",
        color: "colore"
    },
    imagesThumbsPath:"",//"images/thumbs/",         // NOT USED: appended before the path coming from JSON
    imagesFullPath:"",//"images/",                  // NOT USED: appended before the path coming from JSON
    
    imageSquareSize:1.8,                            // max size of thumbnail (longer dimension)
    imageSizeFewerThreshold: 10,                    // if image number is lower than this threshold, the following size correction factor is applied. Set to 0 to disable this behavior
    imageSizeFewerLowerFactor: 1.2,                   // when images are fewer than imageSizeFewerThreshold: they are scaled up by a factor that is between this number (when imageSizeFewerThreshold-1 images) and the following (with 1 image)
    imageSizeFewerUpperFactor: 2.5,                   // when images are fewer than imageSizeFewerThreshold: scale up factor if image number is 1

    imageUseMask: true,                             // if true uses the texture "photomask" from the resources as a mask for images

    imageOutInitialDelay:0.7,                       // thumbnails shuffle: time to wait before start animation
    imageOutDelay:0.02,                             // thumbnails shuffle: delay between each thumbnail sorts out of center during shuffle (do not put 0!)
    imageOutDuration:1.5,                             // thumbnails shuffle: animation duration
    imageOutEase:"Power2.easeInOut",                        // thumbnails shuffle: easing
    imageOutZStart: 0.01,                           // thumbnails shuffle: Z position of point of starting shuffle
    imageOutScaleStart: 0.1,                        // thumbnails shuffle: initial scale, before shuffle
    imageInDelay:0.02,                              // thumbnails reverse shuffle: delay between each thumbnail sorts out of center during shuffle
    imageInDuration:1,                              // thumbnails reverse shuffle: animation duration
    imageInEase:"power4.in",                          // thumbnails reverse shuffle: easing
    imageScaleOnOver:2,                           // hover effect: final scale	
    imageScaleOnOverEase:"power4.in",                  // hover effect: easing	
    imageOnOverAnimationLength:0.01,                 // hover effect: animation duration	
    imageOnOverDistance: 6.1,                       // hover effect: final distance from floor (usually a bit more than imagesFloorEndDistance)
    imageDeactivateRotationDuration: 0.7,           // flip other images when zoom: animation duration
    imageDeactivateRotationEase: "Power4.easeInOut",          // flip other images when zoom: easing	
    imageDeactivateRotationAmount:0,//-Math.PI/6, //Math.PI/2,      // flip other images when zoom: rotation angle (use - to change direction, 0 to disable)	
    imageDeactivateWorldInclineDuration: 3.5,       // incline the world when zoom: animation duration	
    imageDeactivateWorldInclineEase: "Power4.easeInOut",      // incline the world when zoom: easing
    imageDeactivateWorldInclineAmount:0,            // incline the world when zoom: rotation amount
    imagesCircleSections:4,                      // carousel: sections to calculate image spread
    imagesCircleOuterRadius:7.5,                      // carousel: outer radius
    imagesCircleInnerRadius:2.8,                      // carousel: inner radius (images will be randomly positioned between inner and outer radiuses)
    imagesCircleRotationDuration:500,               // carousel rotation: speed (duration of full rotation)
    imagesCircleRotationDirection:-1,               // carousel rotation: direction
    imagesCircleRotationSpeed:1,                    // carousel rotation: speed multiplier on drag
    imagesCircleRotationMaxSpeed:33.5,                 // carousel rotation: maximum allowed speed
    imagesFloorStartDistance:2,                  // distance from the floor of the nearest thumbnail
    imagesFloorEndDistance:6,                    // distance from the floor of the farthest thumbnail

    thumbnailChangeDelay:0.02,                  // delay between each image changes from thumbnail to color and back

    loaderDistance:0.001,                          // full image loading animation: distance above the thumbnail
    loaderRotationDuration:0.5,                       // full image loading animation: speed of rotation (duration of full rotation)

    intro: {                                    // parameters for the intro image        
        size: 1.5,                                // intro image size
        color: "#fff2f2",                       // base color of image
        startPosition: {
            x:0,y:0,z:21                        // position of intro image at start
        },
        endPosition: {
            x:0,y:0,z:32                        // position of intro image at the end of animation
        },
        animationLength:1.4,                      // animation duration
        animationEase:"expo.in",           // animation easing
        enterOpacityDuration: 2,            // enter opacity fade animation: duration
        enterOpacityEase:"expo.in",          // enter opacity fade animation: easing
        enterOpacityFinalValue: 7,         // enter opacity fade animation: final opaciy value (set greater than 1 to get full opacity when using alpha mask)
        enterScaleDuration: 2.5,               // enter opacity scale animation: duration
        enterScaleEase:"linear",            // enter opacity scale animation: easing
        enterScaleStartingFactor: 0.8,       // enter opacity scale animation: starting scale
    },
    
    fullImage: {
        animationInLength:0.7,                      // full image moving towards camera: animation duration
        animationInEase:"expo.in",           // full image moving towards camera: easing
        animationOutLength:0.05,                       // full image returning back to carousel: animation duration
        animationOutEase:"expo.in",                  // full image returning back to carousel: easing
        scaleRatio: 3,                              // full image moving towards camera: scale factor
        scaleRatioCorrection: 0.7,                  // when the image is horizontal on vertical devices, reduce, when horizontal on horizontal increase
        scaleRatioPinchFactor: 0.1,                 // multiplier factor on pinch (<1)
        scaleRatioWheelFactor: 10,                   // additional multiplier when using wheel (multiplied with scaleRatioPinchFactor)
        position: {
            x:0,y:0,z:15                             // full image moving towards camera: new position
        },
        distortionDisable: true,                    // disable distortion animation
        distortionDelay: 0.5,                       // full image loaded distortion: delay defore animation
        distortionLength: 0.5,                      // full image loaded distortion: animation duration
        distortionEase: "easeInOut",                // full image loaded distortion: easing
        distortionAmount: 0.1,                       // full image loaded distortion: distortion amount
        fadePlaneColor: "#eaeaea",                  // plane that hides thumbnails: default color (used only when image has no color) (should be neutral R=G=B)
        fadePlaneAddColor: "#666666",                  // plane that hides thumbnails: color to add to image color (should be neutral R=G=B)
    },

    prevImage: {
        animationLength:1,                      // old full image goes away: animation duration
        animationEase:"power4.in",                // old full image goes away: easing
        position: {
            x:-10,y:0,z:25                             // old full image goes away: new position
        },
    },

    nextImage: {
        animationLength:1,                      // old full image goes away: animation duration
        animationEase:"power4.in",                // old full image goes away: easing
        position: {
            x:10,y:0,z:25                             // old full image goes away: new position
        },
    },

    camera:  {
        FOV: 30,                                    // camera: field of view
        near: 0.5,                                  // camera: near clipping
        far: 50,                                    // camera: far clipping
        position:  {
            x:0,y:0,z:30                             // camera: initial position
        },
        positionInside:  {
            x:0,y:0,z:45                            // camera: position to move temporarly when shuffling
        },
        movingDuration: 0.8,                        // camera moving temporarly when shuffling: duration
        movingBackOffset: 0.1,                        // interval between camera moving animations. If zero, camera moves as soon as images start to be shuffled
        movingEase:"power4.easeInOut",                        // camera moving temporarly when shuffling: ease
        mouseAnimationAmplitude: 0.1,                 // mouse move effect: amplitude of motion (inverse)
        deviceAnimationAmplitude: 400,                 // device rotate effect: amplitude of motion (inverse)
        animationSmooth: 0.1,                       // mouse move effect: smoothing (inverse)
        orbitSmooth: 0.005,                          // mouse drag camera smoothing (inverse)
        maxPolarAngle:Math.PI/120,                    // max angle (radians) permitted vertically when orbiting camera (click and drag)
        maxAzimuthAngle:Math.PI/120,                  // max angle (radians) permitted horizontally when orbiting camera (click and drag)
        maxDistance: 0                              // max distance permitted when zooming camera (mouse wheel, ctrl+drag)
    },
    
    colorDial: {                                    // color select dial
        floorDistance:1,                          // distance from floor (when logohide==false should be behind the logo [logoFloorDistance] and over [imagesFloorEndDistance])
        innerRadius: 0.65,                           // inner radius (IGNORED when logohide==true and using shutter)
        outerRadius: 1.45,                           // outer radius (IGNORED when logohide==true and using shutter)
        peepStartScale: 0.95,                       // quick peep animation: start diameter (IGNORED when logohide==true)
        peepEndScale: 2.65,                         // quick peep animation: end diameter (IGNORED when logohide==true)
        peepDuration: 2,                            // quick peep animation duration
        peepEase:"expo.in",                            // quick peep animation: ease
        peepInterval:2.5,                             // quick peep animation: repeat every [] seconds
        gapPercent: 0.05,                            // space (percentage of size) between slices (negative to enlarge them)
        closeDuration: 0.5,                         // open animation: duration
        closeEase:"expo.in",                         // open animation: ease
        openDuration: 0.2,                          // close animation: duration
        openEase:"expo.in",                          // close animation: ease
        colors: [
            ["#ebe546","colore","giallo"],
            ["#78e378","colore","verde"],
            ["#4f89e0","colore","blu"],
            ["#5C5C5C","colore","nero"],
            ["#b088e3","colore","viola"],
            ["#e35944","colore","rosso"],
            ["#eb8c3f","colore","arancione"],
            ],
    },

    colorShutter: {                                    // color select shutter
        // some of the parameters are in colorDial section:  
        // floorDistance, closeDuration, closeEase, peepDuration, peepEase, colors, peepInterval, gapPercent
        borderWidth: 0.2,                              // border ring: width
        borderDistance: 0.2,                           // border ring: distance from logo (should cover the blades)
        fakeShadowScale:0,                              // scale of the fake shadow behind (multiplies logoRadius)
        fakeShadowPosition: {
            x:-0.1, y:-0.05, z:-0.01                           // position of the fake shadow
        },
        bladeMaxDistance: 0.35,                          // blades max size when entering the ring
        bladeZRotation:0.0,                            // rotation of blades
        bladeClosedScale: {
            x:0, y:0.3, z:0                               // scale of each blade when closed. Can change the animation effect
        },
        bladePeepScale: {
            x:0.4, y:0.7, z:0                               // scale of each blade when closed. Can change the animation effect
        },
        bladeOpacity: 0.5,                          // transparency of colors (used only if there isn't a colorShutterOpacity texture in the resources)
        logoScaleOnOpen: 0.5,                            // when clicked, logo scales down of this pecentage
        shutterActiveScaleX: 1.3,
        shutterActiveScaleY: 1.2,
        shutterActiveScaleZ: 1,
        defaultColor: "#fff2f2",                    // this color will shade the texture, if different from white.
        galleryAddColor: "#aaaaaa",                // this color will be added to the gallery color and used as background (should be neutral R=G=B)
    },
    
    worldIsFlat: true,                          // if true, the floor will be flat: no 3D, no normal maps, no lights, no shadows, no environment map
    
    toneMappingExposure: 1,                         // tone mapping exposure (almost no effect)
    clearColor:"#f3e8e8",                           // background color (usually not to be seen)
    imageCastShadow:false,                          // thumbnails can cast shadows (they don't receive shadows)
    environment: {
        sunLightColor:"#FFFFFF",                    // main light color (does not affect images)
        sunLightIntensity:2.5,                        // main light intensity (does not affect images)
        sunLightPosition: {
            x:2.5, y:3, z:10                           // main light intensity (does not affect images)
        },
        pointLightActive : true,                    // activate additional point light
        pointLightShadows : false,                  // point light cast shadows
        pointLightColor:"#FFFFFF",                    // point light color (does not affect images)
        pointLightIntensity:30,                        // point light intensity (does not affect images)
        pointLightDistance:20,                        // point light max distance
        pointLightDecay:2,                        // point light: The amount the light dims along the distance of the light
        pointLightPosition: {
            x:3, y:4, z:6                           // main light intensity (does not affect images)
        },
        shadowNear:2,                               // main light shadows near clipping
        shadowFar:15,                               // main light shadows far clipping
        shadowSide:8,                             // main light shadows lateral clipping
        shadowMapSize:1024,                         // main light shadows resolution
        shadowBlurRadius:5,                         // main light shadows blurring
        shadowNormalBias:0.05,                      // main light shadows normal bias (almost no effect)
        environmentMapIntensity:1                   // environment map intensity (light in shadows and general tone according to environment map)
    },
        
    floorDefaultColor: "#fff2f2",                   // standard color of the floor
    floorGalleryAddColor: "#aaaaaa",                // this color will be added to the gallery color and used as background (should be neutral R=G=B)
    floorSize:50,                                   // floor circle size
    floorTiling:1000,                                // floor texture tiling
    floorNormalScale:0.4,                           // floor texture normal intensity (amount of bump)
    
    logoHide:true,                                  // if TRUE uses color shutter and hides logo, if FALSE uses old color dial
    logoRadius:1.5,                                 // logo: radius of circle (USED ALSO FOR COLOR SHUTTER WHEN logohide==true)
    logoFloorDistance:11.2,                          // IGNORED WHEN USING COLOR SHUTTER (logohide==true) logo: distance from the floor (should be higher than imagesFloorEndDistance)
    logoBeatDuration:1.5,                           // logo beat: animation duration
    logoBeatInterval:2.5,                           // logo beat: animation repeat delay
    logoBeatScale:1.2,                              // logo beat: max scale
    logoBeatEase:"Elastic.easeOut",                 // logo beat: easing
    logoInclineOnImageDeactivation:false,            // logo: should it incline with world when images are deactivated?
    
    longDescriptionClass:"longDescription",         // CSS class of HTML DIV element for the long description (will be dynamically created by this app)
    shortDescriptionClass:"shortDescription",        // CSS class of HTML DIV element for the short description (will be dynamically created by this app)
    
    longDescriptionAppearDelay: 0,                  // time to wait before showing long description (usually the duration of an animation in beforeLongDescriptionAppears function)
    longDescriptionDisappearDelay: 1,               // time to wait before hiding long description (usually the duration of an animation in beforeLongDescriptionDisappears function)
    beforeLongDescriptionAppears: (longDescrEl) => {         // optional function to call before making long description appear
        

    },
    afterLongDescriptionAppears:(longDescrEl)=>{  // optional function to call after long description appears

        var longDescrEl_a = longDescrEl.querySelector('a');

        //la descrizione ha link?
        if(longDescrEl_a) {
            //split lettere dentro a
            new SplitText(longDescrEl_a, { type: "lines, chars", linesClass: 'split_line', charsClass: 'split_char' });
        }else {
            //split lettere dentro div
            new SplitText(longDescrEl, { type: "lines, chars", linesClass: 'split_line', charsClass: 'split_char' });
        }

        return gsap.timeline({ delay: .8})
            .fromTo(longDescrEl,
            {
                "--clipRadius": "10%",
                "--clipY": "-150%",
            },
            {
                duration: .3,
                "--clipRadius": "100%",
                "--clipY": "-50%",
                ease: "power2.out"
            })
            .fromTo(longDescrEl.querySelectorAll('.split_char'),
            {
                y: '-180%',
                rotation: function(i,el,arr) { return 5 * (arr.length / 2 - i) }
            },
            {
                duration: .2,
                y: 0,
                rotation: 0,
                ease: "expo.out",
                stagger: .02,
            }, "<.2")
            .set('#control-shade', { display: 'flex' })
        //document.getElementById('control-shade').style.display = 'flex'; //mostra shade transparente più alta di tutto per leggere input

    },
    beforeLongDescriptionDisappears:(longDescrEl)=>{         // optional function to call before making long description disappear
        gsap.timeline()
            .fromTo(longDescrEl,
            {
                "--clipRadius": "100%",
                "--clipY": "50%",
            },
            {
                "--clipRadius": "10%",
                "--clipY": "-150%",
                duration: .7,
                ease: "power2.out"
            })
    },
    afterLongDescriptionDisappears:() => {           // optional function to call after long description disappear
        document.getElementById('control-shade').style.display = 'none'; //nascondi shade degli input
    },
}